// @flow

import React, { useEffect } from "react";
import { withPrivateRoute } from "@containers";
import { navigate } from "gatsby";
import { usePermission } from "../hooks";
/**
 * Home Page
 */
function HomePage() {
    const hasPermissionForOwnAssignments = usePermission(
        "navigation.assignments.own.read",
    );
    // go to mijn opdrachten when logging in
    useEffect(() => {
        hasPermissionForOwnAssignments
            ? navigate("opdrachten/mijn-opdrachten/")
            : navigate("aanvragen/mijn-aanvragen/");
    });
    return <div />;
}
export default withPrivateRoute(HomePage);
